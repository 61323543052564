const components = {
  navbar: {
    fbLink: 'https://www.facebook.com/erdingerbrauhaus',
    igLink: 'https://instagram.com/erdinger.brauhaus?utm_medium=homepage',
    mobileLogo: require('../images/navbar/mobile-logo.svg'),
    radler: require('../images/navbar/radler.svg'),
    radlerText: 'Natur Radler',
    logo: require('../images/navbar/logo.svg'),
    logoText: 'ERDINGER Brauhaus',
    helles: require('../images/navbar/helles.svg'),
    hellesText: 'Helles',
    fb: require('../images/navbar/fb.svg'),
    fbText: 'Facebook',
    ig: require('../images/navbar/ig.svg'),
    igText: 'Instagram',
  },
  stickyBottom: {
    text: [
      'Auf geht´s – Probiers doch!',
      'Geh weida, dann konnst mid redn!',
      'A bissl was geht immer!',
      'Sei doch ned dappad!',
    ],
  },
  footer: {
    de: {
      radler: 'NaturRadler',
      helles: 'Original Helles',
      kontakt: 'Kontakt',
      teilna: 'Teilnahmebedingungen',
      handelspartner: 'Handelspartner',
      impresum: 'Impressum',
      datenschutz: 'Datenschutz',
      radlertour: 'RadlerTour',
      events: 'FestlTour',
      presse: 'Presse',
      fbLink: 'https://www.facebook.com/erdingerbrauhaus',
      igLink: 'https://instagram.com/erdinger.brauhaus?utm_medium=homepage',
      fb: require('../images/fb.svg'),
      fbText: 'Facebook',
      ig: require('../images/ig.svg'),
      igText: 'Instagram',
      mobileLogo: require('../images/mobile-logo.svg'),
      logo: require('../images/logo.svg'),
      logoText: 'ERDINGER Brauhaus Bayern',
    },
    en: {
      radler: 'NaturRadler',
      helles: 'Original Helles',
      kontakt: 'Contact',
      teilna: 'Teilnahmebedingungen',
      handelspartner: 'Handelspartner',
      impresum: 'Legal notice',
      datenschutz: 'Data protection terms',
      fbLink: 'https://www.facebook.com/erdingerbrauhaus',
      igLink: 'https://instagram.com/erdinger.brauhaus?utm_medium=homepage',
      fb: require('../images/fb.svg'),
      fbText: 'Facebook',
      ig: require('../images/ig.svg'),
      igText: 'Instagram',
      mobileLogo: require('../images/mobile-logo.svg'),
      logo: require('../images/logo.svg'),
      logoText: 'ERDINGER Brauhaus Bayern',
    }
  },
}

export default components
